import React from 'react';
import PropTypes from 'prop-types';
import { PolicyRow, Typography, Row, Col } from 'components/alf-design';
import { formats, customTypes } from 'utils';
import { STATUS } from 'utils/constants';

const MortgageePaid = ({ billing, showCancellationData, policyStatus }) => {
  const paidInFull = !billing.outstandingBalance;
  const paymentStatus = paidInFull ? 'Paid in Full' : 'Pending Payment';

  return (
    <>
      <Row>
        <Col xs={18}>
          {policyStatus === STATUS.PENDING_CANCEL ? (
            <Typography
              id="status"
              type="caption1"
              component="span"
              style={{
                display: 'inline-block',
                color: '#DC2A2A',
              }}
            >
              This policy is pending cancellation. For billing information,
              please contact your agent.
            </Typography>
          ) : (
            <Typography
              id="status"
              type="caption1"
              component="span"
              style={{
                display: 'inline-block',
              }}
            >
              Your policy is set up as mortgagee paid. For questions or
              assistance regarding your payments, please contact your mortgage
              company or your agent.
            </Typography>
          )}
          {!!billing.pastDueAmount && (
            <Typography
              id="pastDueAmount"
              type="caption1"
              component="span"
              style={{
                display: 'inline-block',
                color: '#DC2A2A',
                marginTop: 10,
              }}
            >
              Your last installment was not paid in full.
            </Typography>
          )}
        </Col>
      </Row>

      <Row gutter={24} className="billing-content">
        <Col xl={24} xxl={24}>
          <div>
            {showCancellationData && (
              <div className="alf-policy-row vertical billing-cancellation">
                <Typography
                  type="caption1"
                  component="span"
                  style={{ color: '#DC2A2A' }}
                >
                  <b>Cancellation Date </b>
                </Typography>

                <Typography
                  id="cancellationDate"
                  type="caption1"
                  component="span"
                  style={{ color: '#DC2A2A' }}
                >
                  {billing.cancellationDate}
                </Typography>
              </div>
            )}
            <Row gutter={24}>
              <Col md={12}>
                <PolicyRow
                  label="Mortgage Company Name"
                  value={billing.payor}
                  vertical
                />

                <PolicyRow
                  label="Payment Status"
                  value={paymentStatus}
                  vertical
                />
              </Col>
              <Col md={12}>
                <PolicyRow
                  label={paidInFull ? 'Amount Paid' : 'Amount Due'}
                  value={formats.moneyFormat(
                    paidInFull
                      ? billing.amountPaid
                      : billing.outstandingBalance,
                  )}
                  vertical
                />

                {(paidInFull ||
                  (!paidInFull &&
                    billing.currentDueAmount > 0 &&
                    billing.currentDueDate)) && (
                  <PolicyRow
                    label={paidInFull ? 'Paid Date' : 'Due Date'}
                    value={formats.dateFormat(
                      paidInFull ? billing.paidDate : billing.currentDueDate,
                    )}
                    vertical
                  />
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

MortgageePaid.propTypes = {
  billing: customTypes.billing,
  showCancellationData: PropTypes.bool,
  policyStatus: PropTypes.string,
};

export default MortgageePaid;
