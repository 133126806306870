import React from 'react';
import PropTypes from 'prop-types';
import { formats, useResponsive, withApi } from 'utils';
import { STATUS } from 'utils/constants';

import {
  Card,
  Typography,
  PolicyRow,
  Row,
  Col,
  List,
  Button,
} from '../../alf-design';

const styles = {
  rowStyle: {
    marginBottom: 24,
  },
  deductiblesRow: {
    marginBottom: 40,
  },

  downloadBtn: {
    position: 'absolute',
    bottom: 0,
  },
  email: {
    color: '#6647DC',
    cursor: 'pointer',
  },
  policyDocuments: {
    textAlign: 'center',
  },
};

const PolicyDetails = ({ policy, toggleDownloads }) => {
  const { showCancellationData } = useResponsive();

  const name = `${policy.insured.firstName} ${policy.insured.lastName}`;
  const effective = `${formats.dateFormat(
    policy.effectiveDate,
  )} to ${formats.dateFormat(policy.expirationDate)}`;
  let statusStyle = {};
  if (
    policy.statusCode === STATUS.CANCELLED ||
    policy.statusCode === STATUS.PENDING_CANCEL
  ) {
    statusStyle = { color: '#DC2A2A' };
  }

  const onEmailClick = (email) => {
    window.location = `mailto:${email}`;
  };

  return (
    <Card style={{ width: '100%' }}>
      <Typography type="heading3" component="h3" style={styles.rowStyle}>
        Policyholder
      </Typography>
      <Row style={styles.rowStyle}>
        <Col sm={12} md={6}>
          <PolicyRow label="Name" value={name} vertical />
        </Col>
        <Col sm={12} md={6}>
          <PolicyRow
            label="Phone"
            value={formats.phoneFormat(policy.insured.phone)}
            vertical
          />
        </Col>
        <Col md={12}>
          <PolicyRow
            label="Policy Contact Email"
            value={policy.insured.email}
            valueStyle={styles.email}
            vertical
            onClick={() => onEmailClick(policy.insured.email)}
            withTab
            tooltip="All policy correspondence will be sent to this email address."
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Typography type="heading3" component="h3" style={styles.rowStyle}>
            Policy Details
          </Typography>
          <Row gutter={8} type="flex">
            <Col md={7}>
              <PolicyRow
                label="Policy Number"
                value={policy.policyNumber}
                vertical
              />
              <PolicyRow label="Effective" value={effective} vertical />
              <PolicyRow
                label="Status"
                value={
                  !policy.statusCode && policy.status
                    ? policy.status
                    : policy.statusCode
                }
                vertical
                valueStyle={statusStyle}
              />
              {showCancellationData &&
                (policy.statusCode === STATUS.CANCELLED ||
                  (policy.statusCode === STATUS.PENDING_CANCEL && (
                    <PolicyRow
                      label="Cancellation Reason"
                      value={
                        policy.cancellationReason // TODO - see if this field exists in real API
                      }
                      vertical
                    />
                  )))}
              {policy.price && policy.price !== 0 && (
                <PolicyRow
                  label="Premium"
                  value={formats.moneyFormat(policy.price)}
                  vertical
                />
              )}
              <PolicyRow label="Product" value={policy.type} vertical />
              <PolicyRow
                label="Underwriting Company"
                value={policy.company}
                vertical
              />
            </Col>
            <Col sm={24} md={17}>
              <List
                title="Coverages"
                data={policy.coverages}
                mapKey={(item) => item.title}
              />
              <br />
              <List
                title="Deductibles"
                data={policy.deductibles}
                style={styles.deductiblesRow}
              />
              <br />
            </Col>
          </Row>
          <Row style={styles.policyDocuments}>
            {policy.policySystem === 'Duck Creek' && (
              <Button
                id="download-policy"
                onClick={(e) => toggleDownloads(true)}
                style={{ fontSize: 16 }}
              >
                Policy Documents
              </Button>
            )}
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

PolicyDetails.propTypes = {
  toggleDownloads: PropTypes.func,
  policy: PropTypes.object,
};

export default withApi(PolicyDetails);
