import React from 'react';
import PropTypes from 'prop-types';
import { formats } from 'utils';

import { Card, Typography, PolicyRow, Link } from '../../alf-design';

const AgencyDetails = ({ policy: { agent } }) => {
  const agentAddress = formats.address(agent);
  const phone = formats.phoneFormat(agent.phone);
  return (
    <Card>
      <Typography
        type="heading3"
        component="h3"
        style={{ marginBottom: '24px' }}
      >
        Agency Details
      </Typography>
      <PolicyRow
        id="organizationName"
        value={agent.organizationName}
        vertical
      />
      <PolicyRow id="agentAddress" value={agentAddress} vertical />
      {phone && (
        <div style={{ marginBottom: '10px' }}>
          <Link id="agentPhone" type="primary" href={`tel:${phone}`}>
            {phone}
          </Link>
        </div>
      )}
      {agent.email && (
        <div style={{ marginBottom: '10px' }}>
          <Link id="agentEmail" type="primary" href={`mailto:${agent.email}`}>
            {agent.email}
          </Link>
        </div>
      )}
    </Card>
  );
};
AgencyDetails.propTypes = {
  policy: PropTypes.object,
};

export default AgencyDetails;
