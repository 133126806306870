/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { useResponsive, useAuth0 } from 'utils';
import { datadogLogs } from '@datadog/browser-logs';

import { Row, Col, CardContainer, Button, Typography } from '../alf-design';

const EmailVerification = ({ location, history }) => {
  const { message, success, code } = queryString.parse(
    decodeURIComponent(location.search),
  );
  const [isLoadLogin, setIsLoadLogin] = React.useState(false);
  const { screenIsDown } = useResponsive();
  let displayMessage = null;
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!message) {
      history.push('/');
    }
  }, [message]);

  if (
    (success === 'true' &&
      code === 'success' &&
      message.indexOf('email was verified') > -1) ||
    (success === 'false' && message.indexOf('can be used only once') > -1)
  ) {
    displayMessage =
      'Thank you for verifying your email address.  Please click the button below to log in.';
  } else {
    displayMessage = message;
  }

  const handleLogin = async () => {
    setIsLoadLogin(true);
    try {
      await loginWithRedirect();
    } catch (error) {
      datadogLogs.logger.error(`Email Verification`, {
        error,
      });
      setIsLoadLogin(false);
    }
  };

  return (
    <CardContainer>
      <Typography type="heading3" component="h3">
        Email Verified
      </Typography>
      <Typography type="caption1" component="span">
        {displayMessage}
      </Typography>

      <Row gutter={24} style={{ marginTop: 70 }}>
        <Col xs={24}>
          <Button
            withLoading={isLoadLogin}
            type="primary"
            fullWidth
            onClick={handleLogin}
            disabled={isLoadLogin}
            style={{
              padding: screenIsDown('sm') ? '10px 20px 0' : '8px 20px 0',
            }}
            link
          >
            Log In
          </Button>
        </Col>
      </Row>
    </CardContainer>
  );
};
EmailVerification.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
};

export default withRouter(EmailVerification);
