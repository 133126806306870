import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './LinkPolicy.css';
import Guidance from 'components/Guidance';
import {
  useAuth0,
  usePolicy,
  withApi,
  validate as externalValidator,
  useForm,
} from 'utils';
import { datadogLogs } from '@datadog/browser-logs';

import {
  Typography,
  Dialog,
  showErrorAlert,
  Button,
  Spinner,
  Form,
  Row,
  FormField,
  MaskInput,
  Input,
} from '../alf-design';

const styles = {
  actions: {
    justifyContent: 'center',
    marginTop: 0,
  },
  heading: {
    display: 'none',
  },
  wrapper: {
    width: 460,
    minHeight: 350,
  },
  success: {
    padding: 12,
    backgroundColor: 'hsla(177, 40%, 66%, 20%)',
    fontSize: 'min(14rem, calc(14px * 1.5))',
    color: '#333333',
    marginBottom: 16,
  },
  errorPolicy: {
    background: '#FEF7F6 0% 0% no-repeat padding-box',
    padding: 12,
    fontSize: 'min(14rem, calc(14px * 1.5))',
    color: '#DC2A2A',
    marginBottom: 16,
  },
};
function validate(values) {
  const errors = {};
  if (!values.zip || !/^\d{5}(?:[-\s]\d{4})?$/.test(values.zip)) {
    errors.zip = 'Invalid property zip zode';
  }

  if (!externalValidator.policyNumber(values.policyNumber)) {
    errors.policyNumber = 'Invalid policy number';
  }

  return errors;
}
const initState = {
  policyNumber: '',
  zip: '',
};

const LinkPolicy = ({ show, onClose, api, history, fromDashboard }) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useForm(initState, validate, true);
  const { setPolicies, policies, loading, setLoading, error } = usePolicy(
    null,
    false,
    {
      loadPolicies: true,
    },
  );
  const [disabledGuidance, setDisabledGuidance] = useState(true);
  const [errorPolicies, setErrorPolicies] = useState({});
  const [successMessage, setSuccessMessage] = useState(null);
  const { getTokenSilently } = useAuth0();

  useEffect(() => {
    if (fromDashboard && show) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (policies) {
      setLoading(false);
    }
  }, [policies, setLoading]);

  useEffect(() => {
    if (error) {
      setLoading(false);
      showErrorAlert({
        message: `${
          error.entity.charAt(0).toUpperCase() + error.entity.slice(1)
        } Error`,
        description: errorDescription(error.err, error.entity),
      });
    }
  }, [error, setLoading]);

  const errorDescription = (err, request) => {
    let description;
    if (err.response) {
      description = err.response.data.error;
    } else if (err.request) {
      description =
        'Network error. Please check your connection and try again.';
    } else {
      description = `Error attempting to fetch your ${request}. Refresh page to try again.`;
    }
    return description;
  };

  const findPolicy = (policyNumber) => {
    const strippedNumber = policyNumber.replace(/ /g, '').trim();
    return policies.find((p) => {
      const sNumber = p.policyNumber.replace(/ /g, '').trim();
      return (
        strippedNumber.includes(sNumber) || sNumber.includes(strippedNumber)
      );
    });
  };

  const onSubmit = (e) => {
    handleSubmit(e, (callback) => {
      const { zip } = values;
      let { policyNumber } = values;
      policyNumber = policyNumber.replace(/  +/g, ' ').trim();
      if (findPolicy(policyNumber)) {
        setSuccessMessage('The policy has already been linked.');
        callback(null);
      } else {
        setSuccessMessage(null);
        api.withAuth
          .post('/identity/policy', {
            policyNumber,
            zip,
          })
          .then(async () => {
            await getTokenSilently({ ignoreCache: true });
            setPolicies(null);
            callback(null);
            history.replace(`/?policy=${policyNumber}`);
          })
          .catch((err) => {
            callback(err);
            if (err.response) {
              if (err.response.status === 400) {
                setErrorPolicies({
                  number: 400,
                  description:
                    'Policy entered was not found. Please contact Slide Customer Service (800) 748-2030.',
                });
              } else {
                setErrorPolicies({
                  number: err.response.status,
                  description: err.response.data.error,
                });
              }
            } else if (err.request) {
              setErrorPolicies({
                number: null,
                description:
                  'Network error. Please check your connection and try again.',
              });
            } else {
              setErrorPolicies({
                number: null,
                description: 'Error attempting to link policy to your account.',
              });
            }
            datadogLogs.logger.error(`Link Policy - ${policyNumber}`, {
              error,
            });
          });
      }
    });
  };

  const isEmpty = (input) =>
    !Object.values(input).some((i) => i !== null && i !== '');

  const handleClose = () => {
    onClose();
    setSuccessMessage(null);
    setErrorPolicies({});
    values.zip = initState.zip;
    values.policyNumber = initState.policyNumber;
  };

  return (
    <Dialog
      invokingElementId="link-policy-button"
      id="link-policy-popup"
      show={show}
      hide={false}
      onClose={handleClose}
      headingStyle={styles.heading}
      actionsStyle={styles.actions}
      wrapperStyle={styles.wrapper}
      actions={
        <>
          <div>
            <Button
              id="button-go-back"
              onClick={handleClose}
              className="link_policy__button"
            >
              Cancel
            </Button>
          </div>
          <div>
            <Button
              id="button-ok"
              type="primary"
              onClick={onSubmit}
              className="link_policy__button"
              disabled={isSubmitting || isEmpty(values) || !isEmpty(errors)}
              withLoading={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </>
      }
    >
      {!loading && policies ? (
        <div className="link_policy__container">
          <div className="link_policy__card">
            <Form onSubmit={onSubmit}>
              <Typography
                type="heading3"
                component="h1"
                style={{ marginBottom: '24px' }}
              >
                Verify Your Existing Policy
              </Typography>
              {errorPolicies.description && (
                <Typography style={styles.errorPolicy}>
                  {errorPolicies.description}
                </Typography>
              )}
              <Row>
                {successMessage && (
                  <Typography style={styles.success}>
                    {successMessage}
                  </Typography>
                )}
                <FormField
                  label="Property Zip Code"
                  error={
                    touched.zip && (errors.zip || disabledGuidance)
                      ? 'Invalid property zip zode'
                      : ''
                  }
                >
                  <MaskInput
                    id="zip-input"
                    placeholder="Zip Code"
                    name="zip"
                    type="zip"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip}
                    error={
                      (!!disabledGuidance && !!touched.zip) ||
                      errorPolicies.number === 400
                    }
                    success={!disabledGuidance && !!touched.zip}
                  />
                </FormField>

                <FormField
                  label="Policy Number"
                  icon={
                    <Guidance
                      zip={values.zip}
                      disabledGuidance={disabledGuidance}
                      setDisabledGuidance={setDisabledGuidance}
                    />
                  }
                  error={touched.policyNumber ? errors.policyNumber : ''}
                >
                  <Input
                    placeholder={
                      errors.zip || disabledGuidance || !touched.zip
                        ? 'Insert Zip Code First'
                        : 'Policy Number'
                    }
                    id="policynumber-input"
                    name="policyNumber"
                    type="text"
                    onChange={(e) => {
                      setErrorPolicies({});
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    value={values.policyNumber}
                    error={
                      errorPolicies.number === 404 ||
                      (!!errors.policyNumber && !!touched.policyNumber)
                    }
                    success={
                      errorPolicies.number !== 404 &&
                      !errors.policyNumber &&
                      !!touched.policyNumber
                    }
                    disabled={!!disabledGuidance || !touched.zip}
                  />
                </FormField>
              </Row>
            </Form>
          </div>
        </div>
      ) : (
        <div className="link_policy__circularProgress">
          {loading && <Spinner id="dashboard-spinner" size={80} />}
        </div>
      )}
    </Dialog>
  );
};

LinkPolicy.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  api: PropTypes.object,
  history: PropTypes.object,
  fromDashboard: PropTypes.bool,
};

export default withApi(LinkPolicy);
