import { showErrorAlert } from 'components/alf-design';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';

import config from '../config';

const usePayment = ({ submitPayment, getPolicy, policyNumber }) => {
  const [dataResult, setDataResult] = useState(null);
  const [error, setError] = useState(null);
  const initEventHandlers = (container, toggleLoading, insured) => {
    container.off();

    container.on('portalOne.load', function () {
      toggleLoading();
    });

    container.on('portalOne.paymentComplete', function (e, data) {
      const method = data.paymentCategory === 'CreditCard' ? 'card' : 'bank';
      const successData = {
        method,
        data: {
          paymentInformation: {
            transactionId: data.transactionId,
            tokenId: data.tokenId,
            authCode: method === 'card' ? data.authCode : '',
            transactionDate: moment(data.transactionDate).format('YYYY-MM-DD'),
            cardType: method === 'card' ? data.cardType : '',
            lastFourDigits: method === 'card' ? data.lastFourDigits : '',
          },
          orderInformation: {
            amountDetails: {
              totalAmount: `${data.paymentAmount}`,
              currency: 'USD',
            },
            billTo: {
              firstName: insured?.firstName,
              lastName: insured?.lastName,
              address1: insured?.street,
              city: insured?.city,
              state: insured?.state,
              zipCode: insured?.zip,
            },
          },
        },
      };
      setDataResult(successData);
    });

    container.on('portalOne.error', function (e, data) {
      setError(data);
    });

    container.on('portalOne.unload', function () {
      if (config.oneIncWebhook === '1') {
        getPolicy(policyNumber);
      }
    });
  };

  const getCustomerId = (customerName, partyId) => {
    return new Promise((resolve, reject) => {
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-Type': 'application/json',
        },
        body: JSON.stringify({
          PortalOneAuthenticationKey: config.portalOneAuthenticationKey,
          ExternalCustomerId: partyId,
          CustomerName: customerName,
        }),
      };

      fetch(`${config.portalOneApiUrl}/Customer/CreateAccount`, options)
        .then((response) => response.json())
        .then((response) => resolve(response.CustomerId))
        .catch((err) => {
          datadogLogs.logger.error(`Create OneInc Account - ${policyNumber}`, {
            err,
          });
          showErrorAlert({
            message: err,
            description:
              'Something went wrong submitting your payment. Please check your connection and try again.',
          });
        });
    });
  };

  useEffect(() => {
    if (dataResult) {
      window.$('#portalOneContainer').on('portalOne.unload', function () {
        if (dataResult && config.oneIncWebhook === '0') {
          submitPayment(dataResult);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataResult]);

  useEffect(() => {
    if (error !== null) {
      datadogLogs.logger.error(`OneInc Error`, { error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleOneIncPayment = (data, toggleLoading) => {
    getCustomerId(
      `${data?.insured?.firstName} ${data?.insured?.lastName}`,
      data?.billing?.payorPartyId,
    ).then((custId) => {
      fetch(
        `${config.portalOneApiUrl}/Session/Create?portalOneAuthenticationKey=${config.portalOneAuthenticationKey}&CustomerId=${custId}`,
      )
        .then((response) => response.json())
        .then((result) => {
          let container = window.$('#portalOneContainer');
          container.portalOne();
          initEventHandlers(container, toggleLoading, data?.insured);
          let dialog = container.data('portalOne');
          dialog.makePayment({
            paymentCategory: 'UserSelect',
            feeContext: 'PaymentWithFee',
            amountContext: 'AmountDueOnly',
            minAmountDue:
              data?.billing?.pastDueAmount ?? data?.billing?.currentDueAmount,
            accountBalance: data?.billing?.outstandingBalance,
            billingZip: data?.insured?.zip,
            billingAddressStreet: `${data?.insured?.street}, ${data?.insured?.city}, ${data?.insured?.state}`,
            policyHolderName: `${data?.insured?.firstName} ${data?.insured?.lastName}`,
            clientReferenceData1: data?.policyNumber,
            clientReferenceData2: `COP_${data?.policyNumber}`,
            clientReferenceData3: data?.billing?.accountId,
            clientReferenceData4: config.oneIncWebhook,
            confirmationDisplay: 'true',
            saveOption: 'Save',
            acknowledgmentRequired: 'true',
            customerId: custId,
            sessionId: result.PortalOneSessionKey,
            accountGroupCode: 'Slide Insurance Premium',
          });
        });
    });
  };

  return { handleOneIncPayment };
};

export default usePayment;
