/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { motion } from 'framer-motion';
import './SideBar.css';

import Typography from '../Typography';

const SideBar = ({ open, title, onClose, children }) => {
  const sideBarRef = useRef(null);

  const invokingElement = document.activeElement;

  const handledClose = (params) => {
    invokingElement.focus();
    onClose();
  };
  const pressEsc = (e) => {
    e.stopPropagation();
    handledClose();
  };

  // eslint-disable-next-line consistent-return
  const handleTabKey = (e) => {
    if (sideBarRef.current && open) {
      const focusableModalElements = sideBarRef.current.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, [tabindex = "0"]',
      );
      const firstElement = focusableModalElements[0];
      const lastElement = focusableModalElements[
        focusableModalElements.length - 1
      ].disabled
        ? focusableModalElements[focusableModalElements.length - 2]
        : focusableModalElements[focusableModalElements.length - 1];

      if (
        !Array.from(focusableModalElements).includes(document.activeElement)
      ) {
        firstElement.focus();
        return e.preventDefault();
      }
      if (!e.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        return e.preventDefault();
      }

      if (e.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        return e.preventDefault();
      }
    }
  };

  const keyListenersMap = new Map([
    [27, pressEsc],
    [9, handleTabKey],
  ]);

  useEffect(() => {
    const keyListener = (e) => {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    };

    document.addEventListener('keydown', keyListener);

    return () => document.removeEventListener('keydown', keyListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, onClose]);

  const sidebarVariants = {
    hidden: { x: '-100%' },
    visible: { x: 0 },
    exit: { x: '-100%' },
  };

  const siderBar = (
    <>
      {open && <div className="alf-background" onClick={handledClose} />}

      <motion.div
        role="complementary"
        className="alf-sidebar"
        aria-label="Side Bar"
        initial="hidden"
        animate={open ? 'visible' : 'hidden'}
        exit="exit"
        variants={sidebarVariants}
        transition={{ duration: 0.01, ease: 'easeInOut' }}
      >
        <div className="wrapper" ref={sideBarRef} role="navigation">
          <Typography
            type="menuTitle"
            component="h1"
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>

          {children}
        </div>
      </motion.div>
    </>
  );
  return ReactDOM.createPortal(siderBar, document.body);
};

SideBar.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.any,
};

export default SideBar;
