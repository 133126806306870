import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useResponsive, formats, withApi } from 'utils';
import { datadogLogs } from '@datadog/browser-logs';

import { Typography, Dialog, Table, showErrorAlert } from '../alf-design';

const styles = {
  actions: {
    justifyContent: 'flex-end',
  },
  button: {
    width: 160,
  },
};

const paymentId = (payment) => payment.datePaid;

const PaymentHistory = ({ show, onClose, api, policyNumber }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const sortDataByDate = (array) => {
    return array.sort((a, b) =>
      moment(a.datePaid).isBefore(b.datePaid) ? 1 : -1,
    );
  };

  const loadPayments = () => {
    setLoading(true);
    api.withAuth
      .getPayments(policyNumber)
      .then((res) => {
        setData(sortDataByDate(res.data.payments));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        let description;
        if (error.response) {
          description = error.response.data.error;
        } else if (error.request) {
          description =
            'Network error. Please check your connection and try again.';
        } else {
          description = `Error attempting to fetch your payments. Refresh page to try again.`;
        }

        datadogLogs.logger.error(`Payment History - ${policyNumber}`, {
          error,
        });
        showErrorAlert({
          message: 'Payments Error',
          description: description,
        });
      });
  };
  const { height, width, screenIsDown } = useResponsive();
  const tableMaxHeight = screenIsDown('md') ? '100%' : height / 2;
  useEffect(() => {
    if (show) {
      loadPayments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const renderAmountPaid = (val) => {
    return (
      <>
        {formats.moneyFormat(val.amounPaid)}
        {screenIsDown('md') && (
          <Typography
            component="span"
            type="caption1"
            style={{ fontWeight: 'normal' }}
          >
            {val.paymentType === 'eCheck' ? '\nBank ' : '\nCard x'}
            {val.last4CheckOrCardNum}
          </Typography>
        )}
      </>
    );
  };
  const columns = [
    {
      title: 'Amount Paid',
      key: 'amounPaid',
      typography: 'caption3',
      render: renderAmountPaid,
    },
    {
      title: 'Payment Date',
      key: 'datePaid',
      render: (val) => formats.dateFormat(val.datePaid),
    },
    {
      title: 'Status',
      key: 'status',
      render: (val) => {
        return (
          val.status +
          (val.status === 'Reversed'
            ? `\nOn ${formats.dateFormat(val.lastUpdatedTimestamp)}`
            : '')
        );
      },
    },
  ];
  if (!screenIsDown('md')) {
    columns.push({
      title: 'Payment Type',
      key: 'paymentType',
      render: (val) => {
        return val.paymentType === 'eCheck' ? 'Bank Account' : 'Credit Card';
      },
    });
  }
  return (
    <Dialog
      invokingElementId="payment-history-button"
      id="payment-history-popup"
      show={show}
      onClose={onClose}
      actionsStyle={styles.actions}
      wrapperStyle={{
        padding: width < 400 ? 0 : 24,
        height: width < 400 && '100%',
      }}
      headingStyle={{
        marginBottom: 35,
        padding: width < 400 ? '24px 24px 0px 24px' : 0,
      }}
      heading={
        <Typography type="heading3" component="h2">
          Payment History
        </Typography>
      }
    >
      <div
        style={{
          maxHeight: tableMaxHeight,
          overflowY: 'auto',
        }}
      >
        <Table
          titleNoWrap={screenIsDown('md')}
          columns={columns}
          data={data}
          loading={loading}
          dataRowId={paymentId}
          payment
        />
      </div>
    </Dialog>
  );
};

PaymentHistory.propTypes = {
  policyNumber: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withApi(PaymentHistory);
