import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Typography from '../Typography';
import './PolicyRow.css';
import Tooltip from '../Tooltip';

const PolicyRow = ({
  value,
  className,
  keepVertical,
  vertical = keepVertical,
  label,
  style,
  valueStyle,
  onClick,
  verticalLine,
  newline,
  subtitle = '',
  withTab = false,
  labelStyle,
  tooltip = null,
  id,
}) =>
  !!value && (
    <dl
      className={clsx(
        'alf-policy-row',
        className,
        keepVertical && 'keep-vertical',
        vertical && 'vertical',
      )}
      style={style}
    >
      <dt>
        <Typography
          style={labelStyle}
          type={vertical ? 'caption1' : 'paragraph'}
          component="span"
        >
          <b>
            {label}
            {!vertical && ':'}{' '}
          </b>
        </Typography>
        {tooltip && (
          <Tooltip
            ariaLabel="Policy Contact Email Tooltip"
            text={tooltip}
            dataFor="policyEmail"
          />
        )}
      </dt>
      {newline && <br className="hidden-lg" />}
      {subtitle !== '' && (
        <>
          {newline && <br className="hidden-lg" />}
          <Typography
            type={vertical ? 'caption1' : 'paragraph'}
            component="span"
            style={valueStyle}
            onClick={onClick}
          >
            {subtitle}
          </Typography>
          <br className="hidden-lg" />
        </>
      )}
      <dd style={{ margin: 0 }}>
        <Typography
          id={id || label}
          type={vertical ? 'caption1' : 'paragraph'}
          component="span"
          style={valueStyle}
          onClick={onClick}
          withTab={withTab}
        >
          {value}
        </Typography>
      </dd>
      {verticalLine && <div className="v-line" />}
    </dl>
  );

PolicyRow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  valueStyle: PropTypes.object,
  verticalLine: PropTypes.bool,
  newline: PropTypes.bool,
  onClick: PropTypes.func,
  subtitle: PropTypes.string,
  withTab: PropTypes.bool,
  labelStyle: PropTypes.object,
  keepVertical: PropTypes.bool,
  vertical: PropTypes.bool,
  tooltip: PropTypes.string,
  id: PropTypes.string,
};

export default PolicyRow;
