import React, { useState, useEffect, useLayoutEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { useResponsive, useAuth0, usePolicy } from 'utils';

import Dashboard from '../dashboard';
import Footer from './Footer';
import NotFound from './NotFound';
import { AppBar, Confirm, SideBar, Option } from '../alf-design';
import Background from './Background';
import Help from '../Help';
import UserProfile from '../UserProfile';

import './PublicLayout.css';

const width = window.innerWidth;

const styles = {
  contentSm: {
    marginTop: 10,
    marginBottom: width < 576 ? 10 : 80,
    marginLeft: 10,
    marginRight: 10,
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column'
  },
  content: {
    margin: 52,
    marginTop: 33,
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column'
  },
  contentItem: {
    flex: '1 0 0%',
    maxWidth: '100%'
  }
};

const Layout = ({ history, location }) => {
  const {
    isAuthenticated,
    logout,
    user: { name }
  } = useAuth0();
  const [showConfirm, setShowConfirm] = useState(false);
  const { screenIsUp } = useResponsive();
  const [openSideBar, setOpenSideBar] = useState(false);

  const [queryParams, setQueryParams] = useState(null);
  const { selectedPolicy } = usePolicy(
    queryParams ? queryParams.policy : null,
    {
      loadPolicies: true
    }
  );
  useEffect(() => {
    setQueryParams(queryString.parse(location.search));
  }, [location.search]);

  useLayoutEffect(() => {
    document.title = 'Online Policy Access for Slide Insurance Policyholders';
    const { code, state, ...params } = queryString.parse(location.search);

    if (code || state) {
      history.replace(`${location.pathname}?${queryString.stringify(params)}`);
    }
  }, [history, location]);

  const handleConfirm = () => {
    window.history.replaceState(null, null, '/');
    logout({ returnTo: window.location.origin });
  };
  const handleHelp = () => {
    setOpenSideBar(false);
    history.push(`/help`);
  };
  const handleUserProfile = () => {
    setOpenSideBar(false);
    history.push('/user-profile');
  };
  const handleDashboard = () => {
    setOpenSideBar(false);
    if (selectedPolicy) {
      history.replace(`/?policy=${selectedPolicy.policyNumber}`);
    } else {
      history.replace('/');
    }
  };
  const handleSideBar = (e) => {
    e.preventDefault();
    setOpenSideBar(!openSideBar);
  };
  const handleClose = () => {
    setShowConfirm(false);
  };

  const handleLogout = () => {
    window.sessionStorage.clear();
    setOpenSideBar(false);
    setShowConfirm(true);
  };

  return (
    <div
      style={{
        position: 'relative',
        height: '100%'
      }}
    >
      <SideBar
        open={openSideBar}
        title={name}
        onClose={() => setOpenSideBar(false)}
      >
        <>
          <Option
            title="My Profile"
            iconName="userCircle"
            onClick={handleUserProfile}
          />
          <Option
            alt="Frequently asked questions"
            title="FAQ"
            iconName="questionCircle"
            onClick={handleHelp}
          />
          <Option title="Log Out" iconName="logout" onClick={handleLogout} />
        </>
      </SideBar>
      <Background>
        <AppBar
          onHelp={handleHelp}
          onLogout={() => setShowConfirm(true)}
          onUserProfile={handleUserProfile}
          onSideBar={handleSideBar}
          onLogo={handleDashboard}
        />
        <main
          id="main-content"
          style={screenIsUp('md') ? styles.content : styles.contentSm}
          role="main"
        >
          <div aria-hidden={openSideBar} style={styles.contentItem}>
            <Switch>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/login" component={Dashboard} />
              <Route exact path="/help:policyNumber?" component={Help} />
              <Route exact path="/user-profile" component={UserProfile} />
              <Route path="/page-not-found" component={NotFound} />
              <Redirect from="*" to="/page-not-found" />
            </Switch>
          </div>
        </main>
        <Confirm
          show={showConfirm}
          onConfirm={handleConfirm}
          onClose={handleClose}
          title="Are you sure?"
          confirmTxt="Log out"
          cancelTxt="Cancel"
          confirmId="logout-button"
          cancelId="cancel-button"
          ariaLabel="Log Out"
        />
        <Footer isAuthenticated={isAuthenticated} />
      </Background>
    </div>
  );
};

Layout.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(Layout);
