import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { datadogLogs } from '@datadog/browser-logs';

import { showErrorAlert } from '../components/alf-design/Alert';
import useStorage from './useStorage';

// modified from https://auth0.com/docs/quickstart/spa/react/01-login

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState(
    window.history.state,
    document.title,
    window.location.pathname,
  );

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [passwordChanged, setPasswordChanged] = useStorage(
    'passwordChanged',
    null,
  );

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = new Auth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (
        window.location.pathname === '/' &&
        window.location.search.includes('code=')
      ) {
        try {
          const { appState } = await auth0FromHook.handleRedirectCallback();

          if (!window.location.search.includes('changePassword=true')) {
            onRedirectCallback(appState);
          } else {
            window.location.replace(
              `${window.location.origin}/user-profile?changePassword=true`,
            );
          }
        } catch (error) {
          datadogLogs.logger.error(`Login error`, {
            error,
          });
          showErrorAlert({
            message: 'Login error:',
            description: error,
          });
          auth0FromHook.logout({ returnTo: window.location.origin });
        }
      }

      try {
        await auth0FromHook.getTokenSilently();
        const authenticated = await auth0FromHook.isAuthenticated();
        setIsAuthenticated(authenticated);
        if (authenticated) {
          setUser(await auth0FromHook.getUser());
        }
        setLoading(false);
      } catch (error) {
        if (passwordChanged) {
          setPasswordChanged(false);
          const url = await auth0FromHook.buildAuthorizeUrl({
            prompt: 'login',
            // eslint-disable-next-line camelcase
            redirect_uri: window.location.origin,
          });
          window.location.href = `${url}&passwordChanged=true`;
        } else {
          if (
            error.error === 'unauthorized' &&
            error.error_description === 'session_expired'
          ) {
            const url = await auth0FromHook.buildAuthorizeUrl({
              prompt: 'login',
              // eslint-disable-next-line camelcase
              redirect_uri: window.location.origin,
            });
            window.location.href = `${url}&sessionExpired=true`;
            return;
          }

          if (
            error.error !== 'login_required' &&
            error.error_description !== 'login_required'
          ) {
            showErrorAlert({
              message: 'Authentication error:',
              description: error.error_description,
            });
          }
          setLoading(false);
        }
        datadogLogs.logger.error(`Login error`, {
          error,
        });
      }
    };
    initAuth0();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const auth0User = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(auth0User);
  };

  const loginWithRedirect = (options) =>
    auth0Client.loginWithRedirect({ prompt: 'login', ...options });

  const buildAuthorizeUrl = (options) =>
    auth0Client.buildAuthorizeUrl({ prompt: 'login', ...options });

  const getTokenSilently = async (options) => {
    try {
      return await auth0Client.getIdTokenClaims(options);
    } catch (error) {
      if (
        error.error === 'unauthorized' &&
        error.error_description === 'session_expired'
      ) {
        const url = await auth0Client.buildAuthorizeUrl({
          prompt: 'login',
          // eslint-disable-next-line camelcase
          redirect_uri: window.location.origin,
        });
        window.location.href = `${url}&sessionExpired=true`;
        return null;
      }

      if (
        error.error !== 'login_required' &&
        error.error_description !== 'login_required'
      ) {
        datadogLogs.logger.error(`Authentication error`, {
          error,
        });
        showErrorAlert({
          message: 'Authentication error:',
          description: error.error_description,
        });
      }
      return null;
    }
  };

  const logout = (...p) => auth0Client.logout(...p);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        buildAuthorizeUrl,
        handleRedirectCallback,
        loginWithRedirect,
        getTokenSilently,
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.any,
  onRedirectCallback: PropTypes.func,
};
