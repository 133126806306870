import React from 'react';
import PropTypes from 'prop-types';
import { formats } from 'utils';

import Typography from '../Typography';
import './List.css';

const List = ({ title, data, style, mapKey = (item, index) => index }) => (
  <div className="alf-list" style={style}>
    <Typography type="caption1" component="span">
      <b>{title}</b>
    </Typography>
    {data &&
      data.length > 0 &&
      data.map((item, index) => (
        <div className="row" key={mapKey(item, index)}>
          <Typography
            className="list-label"
            type="caption1"
            component="span"
            style={{ marginRight: 8, flexGrow: 9 }}
          >
            {item.title}
          </Typography>
          <Typography
            id={item.title}
            type="caption1"
            component="span"
            className="price"
            style={{ flexGrow: 1 }}
            wordNoWrap
          >
            {formats.moneyWithoutDecimalsFormat(item.value)}
          </Typography>
        </div>
      ))}
  </div>
);

List.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  style: PropTypes.object,
  mapKey: PropTypes.func,
};

export default List;
