import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import * as FramerMotion from 'framer-motion';

import Icon from '../Icon';
import Button from '../Button';
import './ExpansionPanel.css';

const ExpansionPanel = ({
  className,
  header,
  children,
  onChange,
  expanded: expandedProp,
  headerStyle,
  id,
  claim = false,
}) => {
  const controlled = !!onChange;

  const [expandedState, setExpanded] = useState(false);
  const [divFocus, setDivFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const divRef = useRef(null);

  const expanded = controlled ? expandedProp : expandedState;

  const handleChange = (e) =>
    controlled ? onChange(e, !expanded) : setExpanded(!expanded);

  const handleKeyUp = (e) => {
    if (
      e &&
      ((e.shiftKey && e.keyCode === 9) || e.keyCode === 9 || e.keyCode === 13)
    ) {
      handleChange(e);
    }
  };
  const { motion } = FramerMotion;
  return (
    <div
      className={clsx(
        'alf-panel',
        className,
        expanded && 'expanded',
        divFocus && !claim && 'onFocus',
      )}
    >
      <div
        id={id}
        data-testid={id}
        role="button"
        tabIndex="0"
        className="summary"
        onFocus={() => setDivFocus(true)}
        onBlur={() => setDivFocus(false)}
        onClick={handleChange}
        onKeyUp={handleKeyUp}
        style={headerStyle}
        aria-controls={children.props.id}
        aria-expanded={!!expanded}
      >
        <div className="content">{header}</div>
        {claim ? (
          <div className="expand-icon">
            <Button
              id="expansion-button"
              ariaLabel="Toggle group"
              style={{ margin: 'auto' }}
              htmlType="button"
              className="alf-panel-button"
              onClick={handleChange}
              onFocus={() => setButtonFocus(true)}
              onBlur={() => setButtonFocus(false)}
            >
              View Details
              <Icon
                alt="Toggle group"
                name={expanded ? 'chevronUp' : 'chevronDown'}
                fontSize={14}
                color={buttonFocus ? '#fff' : '#6648dc'}
                onClick={handleChange}
                className="alf-panel-icon"
                style={{ marginLeft: 5, marginTop: 0 }}
              />
            </Button>
          </div>
        ) : (
          <Icon
            alt="Toggle group"
            name={expanded ? 'chevronUp' : 'chevronDown'}
            fontSize={14}
            color="#483499"
            onClick={handleChange}
          />
        )}
      </div>

      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: expanded ? 'auto' : 0,
          opacity: expanded ? 1 : 0,
        }}
        transition={{ duration: 0.06, ease: [0.4, 0, 0.2, 1] }}
        style={{ overflow: 'hidden' }}
      >
        <div className="details" ref={divRef}>
          {children}
        </div>
      </motion.div>
    </div>
  );
};

ExpansionPanel.propTypes = {
  className: PropTypes.string,
  expanded: PropTypes.bool,
  onChange: PropTypes.func,
  header: PropTypes.any,
  children: PropTypes.any,
  headerStyle: PropTypes.object,
  claim: PropTypes.bool,
};

export default ExpansionPanel;
