import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { formats, withApi, useResponsive } from 'utils';
import { datadogLogs } from '@datadog/browser-logs';

import {
  Dialog,
  Typography,
  Table,
  Icon,
  Button,
  Spinner,
  showErrorAlert,
} from '../alf-design';

const documentId = (document) => document.Id;

const PolicyDownloads = ({
  show,
  onClose,
  policyNumber,
  documents: oldData,
  api,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState('');

  const { width, screenIsDown } = useResponsive();

  const sortDataByDate = (array) => {
    return array.sort((a, b) =>
      moment(a.datePaid).isBefore(b.datePaid) ? 1 : -1,
    );
  };

  const loadDocuments = () => {
    setLoading(true);
    api.withAuth
      .getPolicyDeclaration(policyNumber)
      .then((data) => {
        const { data: docs } = data;
        setDocuments(sortDataByDate(docs));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        let description;
        if (error.response) {
          description = error.response.data.error;
        } else if (error.request) {
          description =
            'Network error. Please check your connection and try again.';
        } else {
          description = `Error attempting to download policy.`;
        }
        datadogLogs.logger.error(`Load Documents - ${policyNumber}`, {
          error,
        });
        showErrorAlert({
          message: 'Policy Declaration Error',
          description,
        });
      });
  };

  useEffect(() => {
    if (show) {
      loadDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const downloadDocument = async (document) => {
    try {
      setSelectedDocument(document.Id);
      setDownloading(true);
      await api.withAuth.getPolicyDocument(policyNumber, {
        id: document.Id,
        title: document.Title,
        source: document.Source,
      });
      setDownloading(false);
      setSelectedDocument('');
    } catch (err) {
      setDownloading(false);
      setSelectedDocument('');
      let description;
      if (err.response) {
        description = err.response.data.error;
      } else if (err.request) {
        description =
          'Network error. Please check your connection and try again.';
      } else {
        description = `Error attempting to fetch your documents. Refresh page to try again.`;
      }

      datadogLogs.logger.error(`Download Document - ${policyNumber}`, {
        err,
      });
      showErrorAlert({
        message: 'Policy Error',
        description: description,
      });
    }
  };

  const renderIcon = (val) => {
    if (downloading && selectedDocument === val.Id) {
      return <Spinner />;
    }
    return (
      <Button
        style={{ margin: 'auto' }}
        ariaLabel="Download File"
        id={`downloadFile${val.Id}`}
        onClick={() => downloadDocument(val)}
        icon={
          <Icon
            color="#6648DC"
            name="fileDownload"
            style={{ margin: '4px' }}
            disabled={downloading}
          />
        }
        disabled={downloading}
      />
    );
  };

  const columns = [
    {
      title: 'Document',
      key: 'document',
      render: (val) => val.Title,
    },
    {
      title: 'Download',
      key: 'downloadButton',
      style: {
        width: screenIsDown('md') ? '40%' : null,
        textAlign: 'center',
      },
      render: renderIcon,
    },
  ];

  if (!screenIsDown('md')) {
    columns.splice(1, 0, {
      title: 'Created Date',
      key: 'createdDate',
      render: (val) => formats.dateFormat(val.Date),
    });
  }

  return (
    <Dialog
      invokingElementId="download-policy"
      show={show}
      onClose={onClose}
      heading={
        <Typography type="heading3" component="h2" style={{ marginBottom: 0 }}>
          Policy Documents
        </Typography>
      }
      wrapperStyle={{ padding: width < 400 ? 0 : 24, maxHeight: '85%' }}
      headingStyle={{
        marginBottom: 35,
        padding: width < 400 ? '24px 24px 0px 24px' : 0,
      }}
    >
      <div>
        <Table
          data={documents}
          columns={columns}
          loading={loading}
          dataRowId={documentId}
        />
      </div>
    </Dialog>
  );
};

export default withApi(PolicyDownloads);
