import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './Link.css';

const TYPES = ['default', 'primary'];

const Link = ({
  rel: relProp,
  target,
  className,
  style,
  type,
  href,
  children,
  onClick,
  id,
  isSkipLink = false,
}) => {
  let rel = relProp || '';
  if (target === '_blank' && rel.indexOf('noopener noreferrer') < 0) {
    rel = `${rel} noopener noreferrer`;
  }

  return (
    <a
      href={href}
      className={clsx(
        isSkipLink ? 'alf-skip-link' : 'alf-link',
        className,
        type,
      )}
      target={target}
      rel={rel}
      style={style}
      onClick={onClick}
      id={id}
      data-testid={id}
    >
      {children}
    </a>
  );
};

Link.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  target: PropTypes.string,
  rel: PropTypes.string,
  type: PropTypes.oneOf(TYPES),
  children: PropTypes.string,
  id: PropTypes.string,
  isSkipLink: PropTypes.bool,
};

export default Link;
